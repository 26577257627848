import { render, staticRenderFns } from "./tree.vue?vue&type=template&id=caa9f4d8&scoped=true"
import script from "./tree.vue?vue&type=script&lang=js"
export * from "./tree.vue?vue&type=script&lang=js"
import style0 from "./tree.vue?vue&type=style&index=0&id=caa9f4d8&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "caa9f4d8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/3440258-cci-44866527-275603/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('caa9f4d8')) {
      api.createRecord('caa9f4d8', component.options)
    } else {
      api.reload('caa9f4d8', component.options)
    }
    module.hot.accept("./tree.vue?vue&type=template&id=caa9f4d8&scoped=true", function () {
      api.rerender('caa9f4d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/law-scroller/components/law-menu/tree.vue"
export default component.exports