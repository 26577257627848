import { render, staticRenderFns } from "./nav-bar.vue?vue&type=template&id=3a976c1e&scoped=true"
import script from "./nav-bar.vue?vue&type=script&lang=js"
export * from "./nav-bar.vue?vue&type=script&lang=js"
import style0 from "./nav-bar.vue?vue&type=style&index=0&id=3a976c1e&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a976c1e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/3440258-cci-45622900-830175/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a976c1e')) {
      api.createRecord('3a976c1e', component.options)
    } else {
      api.reload('3a976c1e', component.options)
    }
    module.hot.accept("./nav-bar.vue?vue&type=template&id=3a976c1e&scoped=true", function () {
      api.rerender('3a976c1e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/BigData/pages/law/components/nav-bar.vue"
export default component.exports